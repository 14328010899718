import { React, useState, useEffect } from 'react';
import JSONTableEditor from '../JSONTableEditor';
import DynamicForm from '../DynamicForm';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, IconButton, Tooltip, MenuItem, Select } from '@mui/material';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { makeStyles } from '@mui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LotFilter from '../workInProgress/LotFilter';
import { useTheme } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import CentralCalls from '../../centralCalls';
import ChangePurchaseStatus from './ChangePurchaseStatus';
const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
        marginBottom: theme.spacing(3),
    },
    select: {
        minWidth: 200,
    },
    accordion: {
        borderRadius: '15px',
        margin: '10px 0',
        '&:before': {
            display: 'none',
        },
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:after': {
            display: 'none',
        },
    },
    accordionSummary: {
        backgroundColor: 'rgba(0, 0, 0, .03)',
    },
}));
const PurchaseReady = ({currentOrderStatus,currentOrderStatusDisplay, newOrderStatus, orderStatusDisplay}) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [tableKey, setTableKey] = useState(1);
    const [showChangeStatus, setShowChangeStatus] = useState(false);
    const [selectedStatusChangeLot, setSelectedStatusChangeLot] = useState(false);
    const handleOpenPhaseChange = async (orderRow) => {
        setShowChangeStatus(true);
        setSelectedStatusChangeLot(orderRow.lot_oid);
    };
    const handlePostUpdate = async (orderStatusLotRow) => {
        setShowChangeStatus(false);
    }
    const procedureDetails = {
        procedureName: 'ui_sp_getPurchasingReadyToOrder',
        parameters: { order_type: currentOrderStatus }
    };
    const procedurePullDetails = {
        procedureName: 'ui_sp_getPurchasingReadyToOrder',
        parameters: { order_type: currentOrderStatus }
    };
    const handleWipLinkClick = async (phaseRow) => {
        const path = '/wip?lot_oid=' + phaseRow.lot_oid;
        navigate(path);
      }
    const checkDisplayUndo = (lotRow) => {
        if(currentOrderStatus === 'open') {
            return false;
        }else{
            return true;
        }
    }
    const checkDisplayMarkComplete = (lotRow) => {
        if(currentOrderStatus === 'complete') {
            return false;
        }else{
            return true;
        }
    }
    const handleUndoClick = async (phaseRow) => {

        if(currentOrderStatus === 'ordered') {
            await CentralCalls.upsertData({oid: phaseRow.oid, 'order_status': 'open'}, 'lot', 'update')
        }
        else if(currentOrderStatus === 'complete'){
            await CentralCalls.upsertData({oid: phaseRow.oid, 'order_status': 'ordered'}, 'lot', 'update')
        }
        setTableKey(tableKey + 1);
      }
    const customFunctions = [
        {
            display_name: 'Mark Ordered',
            type: 'check', // type must correspond to a FontAwesome icon name
            func: handleOpenPhaseChange,
            displayCheck: checkDisplayMarkComplete
        },
        {
            display_name: 'WIP',
            type: 'overview',
            func: handleWipLinkClick
          },
          {
            display_name: 'Undo',
            type: 'revert',
            func: handleUndoClick,
            displayCheck: checkDisplayUndo
          }]
    return (
        <>
        <h3>{currentOrderStatusDisplay}</h3>
        <JSONTableEditor procedureDetails={ currentOrderStatus === 'open' ? procedureDetails : procedurePullDetails} hideEdit={true} customFunctions={customFunctions}
                sortOrder={{
                    'material_manufacturer': 'desc',
                    'material_type': 'asc',
                  }}
                  key={{tableKey}}
                  allowDelete={true}
                  delete_column={'order_deleted'} />
            <Dialog fullWidth={true} maxWidth='lg' open={showChangeStatus} onClose={() => setShowChangeStatus(false)}>
              
                <DialogContent >
                    <ChangePurchaseStatus handlePostUpdate={handlePostUpdate} lot_oid={selectedStatusChangeLot} newOrderStatus={newOrderStatus} orderStatusDisplay={orderStatusDisplay} currentOrderStatus={currentOrderStatus} currentOrderStatusDisplay={currentOrderStatusDisplay} ></ChangePurchaseStatus>        
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowChangeStatus(false)} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default PurchaseReady;